import React from 'react';
import SEO from '../components/seo';

const NotFound = ({ children }) => {
  return (
    <React.Fragment>
      <SEO title="404: Not found" />
      <div class="banner-wrapper text-lg-center">
        <h2>404: Not Found</h2>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
